import { addDays, format } from "date-fns";
import { FC, useEffect, useRef, useState } from "react";
import { DateRange, RangeKeyDict } from "react-date-range";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import {
  CheckIsNull,
  IsKeyPressNumber,
} from "../../../../../utils/CommonHelper";

export const getFieldCSSClasses = (
  touched: boolean,
  errors: string,
  value: string,
  readOnly: boolean
) => {
  const classes = ["px-2 py-3 rounded w-full bg-blue-950"]; // Tailwind equivalent of form-control

  if (readOnly) {
    classes.push("text-gray-800", "bg-gray-100", "cursor-not-allowed");
    return classes.join(" ");
  }

  if (touched && (errors || value === "error")) {
    classes.push("border border-red-500"); // Tailwind equivalent of is-invalid
  }

  if (value === "error") {
    classes.push("border border-red-500");
  }

  if (
    touched &&
    !errors &&
    value !== null &&
    value !== "" &&
    value !== "error"
  ) {
    classes.push("border border-green-500"); // Tailwind equivalent of is-valid
  }

  return classes.join(" ");
};

export const Input: FC<{
  field: any;
  form: { touched: Array<boolean>; errors: Array<string> };
  label: string;
  type: string;
  readOnly: boolean;
  placeholder?: string;
  autoComplete?: React.HTMLInputAutoCompleteAttribute;
  isUpperCaseOnly: boolean;
  onChange?: React.FormEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  innerRef?: React.LegacyRef<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
}> = ({
  field,
  form: { touched, errors },
  label,
  type = "text",
  readOnly = false,
  placeholder,
  autoComplete,
  isUpperCaseOnly = false,
  onChange,
  onKeyDown,
  innerRef,
  onFocus,
  onBlur,
  onPaste,
}) => {
  const fieldData = {
    ...field,
    value: field.value === "error" ? "" : field.value,
  };

  const inputClassName = getFieldCSSClasses(
    touched[field.name],
    errors[field.name],
    field.value,
    readOnly
  );

  return (
    <>
      {label && <div className="mb-2 text-input-label">{label}</div>}

      <input
        ref={innerRef}
        type={type === "numberString" ? "text" : type}
        className={inputClassName}
        onKeyDown={(e) => {
          if (type === "number" || type === "numberString") {
            if (!IsKeyPressNumber(e)) {
              e.preventDefault();
            }
          }
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
        onInput={(e: any) => {
          if (isUpperCaseOnly) {
            e.target.value = ("" + e.target.value).toUpperCase();
          }
          if (onChange) {
            onChange(e);
          }
        }}
        {...fieldData}
        onFocus={onFocus ?? fieldData.onFocus}
        onBlur={onBlur ?? fieldData.onBlur}
        onPaste={onPaste}
        placeholder={placeholder}
        readOnly={readOnly}
        autoComplete={autoComplete}
        accept={type === "file" ? "image/*" : undefined}
      />
      <ErrorContainer
        isShow={Boolean(touched[field.name] && errors[field.name])}
        errorMsg={errors[field.name]}
      />
    </>
  );
};

export const PhoneInputField: FC<{
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => any;
  setIsShowPhoneNoError: React.Dispatch<React.SetStateAction<boolean>>;
  isShowPhoneNoError: boolean;
  phoneNoErrorDescription: string;
  setPhoneNoErrorDescription: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  country: string;
}> = ({
  values,
  setFieldValue,
  setIsShowPhoneNoError,
  isShowPhoneNoError,
  phoneNoErrorDescription,
  setPhoneNoErrorDescription,
  name,
  country = "my",
}) => {
  const intl = useIntl();

  return (
    <>
      <PhoneInput
        country={country}
        value={values[name]}
        countryCodeEditable={true}
        onChange={(phone: string) => {
          setFieldValue(name, phone);
          if (isShowPhoneNoError && !CheckIsNull(phone)) {
            setIsShowPhoneNoError(false);
          }
        }}
        onBlur={() => {
          if (CheckIsNull(values[name])) {
            setIsShowPhoneNoError(true);
            setPhoneNoErrorDescription(
              intl.formatMessage({ id: "inputIsMandatory" })
            );
          } else {
            setIsShowPhoneNoError(false);
          }
        }}
        inputProps={{
          className: `w-full ps-12 pr-2 py-3 rounded bg-blue-950 ${
            !isShowPhoneNoError && !CheckIsNull(values[name])
              ? "border border-green-500"
              : isShowPhoneNoError && !CheckIsNull(phoneNoErrorDescription)
              ? "border border-red-500"
              : ""
          }`,
        }}
      />
      <ErrorContainer
        isShow={isShowPhoneNoError && !CheckIsNull(phoneNoErrorDescription)}
        errorMsg={phoneNoErrorDescription}
      />
    </>
  );
};

export const ErrorContainer: FC<{
  errorMsg?: string;
  isShow?: boolean;
  className?: string;
}> = ({ errorMsg, isShow, className }) =>
  isShow ? (
    <div
      className={`mt-1 text-red-500 ${className ? className : ""}`} // Tailwind equivalent for margin and text color
    >
      <div className="text-sm">
        {" "}
        {/* Tailwind equivalent for small help text */}
        <span role="alert">{errorMsg}</span>
      </div>
    </div>
  ) : null;

export const DateRangeField: FC<{
  onDateChange?: (startDate: Date, endDate: Date) => void;
  startDate?: Date;
  endDate?: Date;
  className?: string;
  wrapperClassName?: string;
}> = ({ onDateChange, startDate, endDate, className, wrapperClassName }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: startDate ?? new Date(),
      endDate: endDate ?? new Date(),
      // endDate: endDate ?? addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (ranges: RangeKeyDict) => {
    const range = ranges.selection;
    if (range.startDate && range.endDate) {
      const dateRange = [
        range as { startDate: Date; endDate: Date; key: string },
      ];
      if (onDateChange) {
        onDateChange(dateRange[0].startDate, dateRange[0].endDate);
      }
      setDateRange(dateRange);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} className={wrapperClassName}>
      <div
        className={`w-full py-2 rounded outline-none cursor-pointer bg-blue-950 focus:outline-none ${className}`}
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {format(dateRange[0].startDate, "yyyy-MM-dd")} -{" "}
        {format(dateRange[0].endDate, "yyyy-MM-dd")}
      </div>
      {isOpen && (
        <div className="absolute left-0 right-0 pt-2">
          <DateRange
            dateDisplayFormat={"yyyy-MM-dd"}
            editableDateInputs={false}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            rangeColors={["#2563eb"]} // Primary color for selected range
            className="custom-date-range"
          />
        </div>
      )}
    </div>
  );
};
